import axios from 'axios';
import qs from 'qs';
import { isObject } from '@/utils';

const service = axios.create({
  baseURL: '/',
  timeout: 10000, // request timeout
  headers: {
    'Content-Type': 'application/json',
  },
});

service.interceptors.request.use(
  (config) => {
    const { data } = config;
    if (config.cType) {
      config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      };
    }
    if (!config.multi) {
      const { header } = config;
      console.log(header);
      if (header) {
        Object.keys(header).forEach((key) => {
          config.headers[key] = header[key];
        });
      }
      if (config.cType && config.method === 'post') {
        config.data = qs.stringify(config.data);
      }
      if (config.method === 'post') {
        // config.data = qs.stringify(config.data);
      } else {
        config.params = config.data;
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    if (!isObject(response.data)) {
      return Promise.reject(response.data);
    }
    if (response.data.code <= 0) {
      return Promise.reject(response.data);
    }
    if (response.headers.token) sessionStorage.setItem('token', response.headers.token);
    return response.data;
  },
  (error) => {
    console.log('err' + error); // for debug
    if (typeof error.msg !== 'undefined') {
      console.log(error);
    }
    return Promise.reject(error);
  },
);

export default service;
