<template>
  <div class="help-page">
    <div class="help-wrapper">
      <div v-for="(item, index) in helpList"
        :key="`head_${index + 1}`"
        class="help-list">
        <div class="help-header">
          <span>{{ item.title }}</span>
        </div>
        <div class="help-children"
          v-for="(list, idx) in item.children"
          :key="`list_${idx + 1}`">
          <span @click="jumpAction(list.id)">{{ list.text }}</span>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="button" @click="jumpFeedback">意见反馈</div>
    </div>
  </div>
</template>
<script>
import dataList from './utils/help1.json';
import { newLinkApi } from '@/api/base1';

export default {
  name: 'ThreejzHelp',
  data() {
    return {
      jumpUrl: '',
      isShow: false,
    };
  },
  watch: {
    jumpUrl(val) {
      if (val) {
        this.isShow = true;
      }
    },
  },
  computed: {
    helpList() {
      return dataList;
    },
  },
  created() {
    document.title = '帮助与反馈';
  },
  methods: {
    jumpAction(id) {
      this.$router.push({
        path: 'details',
        query: {
          id,
        },
      });
      // this.getArticle(url);
    },
    jumpFeedback() {
      this.$router.push({
        path: 'feedback',
      });
    },
    getArticle(url) {
      newLinkApi({ url }).then((res) => {
        console.log(res);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.help-page {
  width: 100%;
  padding-bottom: 170px;
}
.help-list {
  span {
    display: block;
    margin-left: 28px;
    font-size: 24px;
    font-weight: 400;
    color: #999999;
    line-height: 33px;
    padding: 22px 0 16px 0;
  }
}
.help-children {
  background: #ffffff;
  span {
    font-size: 30px;
    font-weight: 400;
    color: #333333;
    line-height: 42px;
    padding: 28px 0 ;
    margin-left: 25px;
    display: block;
    text-decoration: none;
    border-bottom: 1px solid #EAE7E7;
  }
}
.footer {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0;
  padding: 22px 0;
  background-color: #ffffff;
  .button {
    height: 88px;
    background: #FFDC4D;
    border-radius: 44px;
    margin: 0px 26px;
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    text-align: center;
    line-height: 88px;
  }
}
iframe {
  width: 100%;
  height: 100%;
}
</style>
