import request from '@/utils/request1';

import { huaxiaUrl, feedbackUrl } from './hostConfig';

const BASE_URL = '/api';
const version = new Date().getTime();

const getGoldNumsApi = (data) => request({ // 查询个人金豆
  url: BASE_URL + '/goldmall/mygold',
  method: 'post',
  data,
});

/** 任务中心用户视频信息
 * *** */
const getTaskStatusApi = (data) => request({
  url: BASE_URL + '/advertisement/userStatus',
  method: 'GET',
  data,
});

/** 任务中心用户信息
 * *** */
const getUserInfoApi = (data) => request({
  url: BASE_URL + '/taskCenter/getUserInfo',
  method: 'POST',
  data,
});

const getTaskInfoApi = (data) => request({
  url: BASE_URL + '/taskCenter/getTaskInfo',
  method: 'POST',
  data,
});

const getSignUpApi = (data) => request({ // 报名
  url: BASE_URL + '/taskCenter/signUp',
  method: 'POST',
  data,
});

const getReceiveRewardsApi = (data, header) => request({ // 去领取会员
  url: BASE_URL + '/taskCenter/receiveRewards',
  method: 'POST',
  data,
  header,
});

const getLooveeLinkApi = (data) => request({ // 获取抓娃娃链接
  url: BASE_URL + '/dollTribe/getLink',
  method: 'GET',
  data,
});

const huaxiaDataSubmitApi = (data) => request({ // 华夏银行用户数据
  url: huaxiaUrl + '/huaxia/api/pre/submit',
  method: 'POST',
  data,
});

const huaxiaMobileSubmitApi = (data) => request({ // 华夏银行用户数据1
  url: huaxiaUrl + '/huaxia/api/get/submit/link',
  method: 'GET',
  data,
});

const newLinkApi = (data) => request({ // 华夏银行用户数据
  url: BASE_URL + '/getLink',
  method: 'GET',
  data,
});

const contentDetailListApi = (data, header) => request({ // 发现页查看更多页面
  url: BASE_URL + '/findPage/tab/contentDetailList',
  method: 'GET',
  header,
  data,
});

const sendSmsApi = (data, header) => request({ // 支付验证码
  url: BASE_URL + '/sendSms',
  method: 'POST',
  header,
  data,
});

const payApi = (data, header) => request({ // 支付
  url: BASE_URL + '/common/pay',
  method: 'POST',
  header,
  data,
  cType: true,
});

const generatorMailOrderApi = (data, header) => request({ // 生成发送邮件订单
  url: BASE_URL + '/order/generatorMailOrder',
  method: 'POST',
  header,
  data,
});

const generatorRecoverDataOrderApi = (data, header) => request({ // 生成恢复数据订支付单
  url: BASE_URL + '/order/generatorRecoverDataOrder',
  method: 'POST',
  header,
  data,
});

const queryUserAccountSmallChangeApi = (data, header) => request({ // 查询零钱
  url: BASE_URL + '/user/account/queryUserAccountSmallChange',
  method: 'POST',
  header,
  data,
});

const getBlindboxActivityInfoApi = (data, header) => request({ // 拆盲盒活动详情
  url: BASE_URL + `/blindBox/getActivityInfo?v=${version}`,
  method: 'GET',
  header,
  data,
});

const getBlindboxOpenTimeApi = (data, header) => request({ // 拆盲盒活动开奖次数
  url: BASE_URL + '/blindBox/getOpenTime',
  method: 'GET',
  header,
  data,
});

const getMyBlindboxPrizeApi = (data, header) => request({ // 开盲盒我的奖品列表
  url: BASE_URL + `/blindBox/getMyPrize?v=${version}`,
  method: 'GET',
  header,
  data,
});

const openMyBlindboxApi = (data, header) => request({ // 开盲盒
  url: BASE_URL + '/blindBox/open',
  method: 'GET',
  header,
  data,
});

const saveAddressBlindboxApi = (data) => request({ // 开盲盒填写地址
  url: BASE_URL + '/blindBox/savePostAddress',
  method: 'POST',
  data,
});

const getAddressBlindboxApi = (data) => request({ // 开盲盒邮寄地址
  url: BASE_URL + '/blindBox/getPostAddress',
  method: 'GET',
  data,
});

const exchangePrizeBlindboxApi = (data, data1, header) => request({ // 开盲盒兑换奖品
  url: BASE_URL + `/blindBox/exchangePrize${data1}`,
  method: 'POST',
  data,
  header,
});

const inviteHelpBlindboxApi = (data, data1) => request({ // 开盲盒去邀请
  url: BASE_URL + `/blindBox/inviteHelp${data1}`,
  method: 'POST',
  data,
});

const getWinnerRotationDataApi = (data, data1) => request({ // 开盲盒中奖名单
  url: BASE_URL + '/blindBox/getWinnerRotationData',
  method: 'GET',
  data,
});

const importDataApi = (data, header) => request({ // 导入数据
  url: '/h5/import/import_data.go',
  method: 'POST',
  data,
  header,
  cType: true,
});

const booksListApi = (data, header) => request({ // 账本数据
  url: '/h5/api/miniProgram/query_miniProgramBooks',
  method: 'POST',
  data,
  header,
  cType: true,
});

const getPrompotActivityApi = (data, data1) => request({ // 2年卡促销活动
  url: BASE_URL + '/card/activity/getActivityInfo',
  method: 'GET',
  data,
});

const getOrderPayStatusApi = (data) => request({ // 2年卡促销活动状态支付状态
  url: BASE_URL + '/card/activity/getOrderPayStatus',
  method: 'GET',
  data,
});

const getUserOrderPayStatusApi = (data) => request({ // 2年卡查询用户最近一个活动状态支付状态
  url: BASE_URL + '/card/activity/getUserOrderPayStatus',
  method: 'GET',
  data,
});

const getUserInfoStatusApi = (data) => request({ // 查询永久会员
  url: BASE_URL + '/user/permanentVipInfo',
  method: 'GET',
  data,
});

const getBuyTimeApi = (data) => request({ // 查询购买次数
  url: BASE_URL + `/blindBox/getBuyTime?v=${version}`,
  method: 'GET',
  data,
});

const getAnnualQueryApi = (data, header) => request({ // 年度账单
  url: BASE_URL + '/annualStatement/queryByUserId',
  method: 'GET',
  data,
  header,
});

const checkPhoneRegisterApi = (data, header) => request({ // 检测手机号是否注册
  url: BASE_URL + `/user/check_cphoneExist?cmobileNo=${data.cmobileNo}`,
  method: 'POST',
  data,
  header,
});

const sendPhoneSmsApi = (data, header) => request({ // 注册发送手机号验证码
  url: BASE_URL + `/user/send_sms?cmobileNo=${data.cmobileNo}&yzmType=${data.yzmType}&channelType=${data.channelType}&timeStamp=${data.timeStamp}&signMsg=${data.signMsg}&imgYzm=${data.imgYzm}`,
  method: 'POST',
  data,
  header,
});

const updatePhoneSmsApi = (data, header) => request({ // 更新图形验证码
  url: `/h5/chargebook/user/get_imgYzm.go?cmobileNo=${data.cmobileNo}`,
  method: 'POST',
  data,
  header,
});

const mobileRegisterApi = (data, header) => request({ // 手机号注册
  url: `/h5/chargebook/user/mobile_register.go?cmodel=${data.cmodel}&cphoneos=${data.cphoneos}&cphonebrand=${data.cphonebrand}&cimei=${data.cimei}&cmobileno=${data.cmobileno}&yzm=${data.yzm}&pwd=${data.pwd}&yzmType=${data.yzmType}&inviteCode=${data.inviteCode}`,
  method: 'POST',
  data,
  header,
});

const goldmallGoodsListApi = (data) => request({ // 新金豆商品列表
  url: BASE_URL + '/goldmall/goodsList',
  method: 'post',
  data,
  cType: true,
});

export {
  getTaskStatusApi,
  getUserInfoApi,
  getTaskInfoApi,
  getSignUpApi,
  getReceiveRewardsApi,
  getLooveeLinkApi,
  huaxiaDataSubmitApi,
  newLinkApi,
  huaxiaMobileSubmitApi,
  contentDetailListApi,
  sendSmsApi,
  payApi,
  generatorMailOrderApi,
  generatorRecoverDataOrderApi,
  queryUserAccountSmallChangeApi,
  getBlindboxActivityInfoApi,
  getBlindboxOpenTimeApi,
  getMyBlindboxPrizeApi,
  openMyBlindboxApi,
  saveAddressBlindboxApi,
  getAddressBlindboxApi,
  exchangePrizeBlindboxApi,
  inviteHelpBlindboxApi,
  getWinnerRotationDataApi,
  importDataApi,
  booksListApi,
  getPrompotActivityApi,
  getOrderPayStatusApi,
  getUserOrderPayStatusApi,
  getUserInfoStatusApi,
  getBuyTimeApi,
  getAnnualQueryApi,
  checkPhoneRegisterApi,
  sendPhoneSmsApi,
  updatePhoneSmsApi,
  mobileRegisterApi,
  getGoldNumsApi,
  goldmallGoodsListApi,
};
