const ORANG_URL = 'http://url/api/app/auth/thirdLoginGetJddToken';

// eslint-disable-next-line import/no-mutable-exports
let huaxiaUrl = '';
// eslint-disable-next-line import/no-mutable-exports
let feedbackUrl = '';
const ROOT_URL = window.location.host;

// const BASE_URL = (rootUrl.includes('.test.') || rootUrl.includes('me.')) ? '/v1' : '/jzadmin/v1';
// 测试域名 //open.testk8s.northlife.com.cn/distribution-bank
switch (ROOT_URL) {
  case 'bank.jz-h5.yofish.com':
    huaxiaUrl = '//huaxia.bank.yofish.com.cn';
    feedbackUrl = '//jz.yofish.com';
    break;
  case 'jz-h5.test.yofish.com':
    huaxiaUrl = '//bank.testk8s.northlife.com.cn';
    feedbackUrl = '//account.gs.9188.com';
    break;
  default:
    huaxiaUrl = '//bank.testk8s.northlife.com.cn';
    feedbackUrl = '//account.gs.9188.com';
    break;
}

export {
  huaxiaUrl, feedbackUrl,
};
